* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;

     font-family: "Poppins", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;

     font-weight: 400;
     font-style: normal;
     color: var(--black);
}

html {
     position: relative;

     font-size: 16px;

     scroll-behavior: smooth;

     width: 100%;
     min-width: 320px;
     height: calc(var(--unit) * 100);
}

body {
     position: relative;
}

.topic-card {
     display: flex;
     padding: 1rem;
     background-color: #ffffff;
     text-align: center;
     flex-direction: column;
     justify-content: center;
     width: 325px;
     height: 257px;
     max-width: 500px;
     align-items: center;
     border-radius: 6% 6% 6% 6% / 5% 5% 5% 5% ;
     box-shadow: 0 3px 13px rgba(0, 0, 0, 0.3);
     padding-top: 40px;
     transition: all 0.4s cubic-bezier(.02,.92,.6,.9);
}

.larger-topic {
     height: 500px;
     width: 90%;
     max-width: 700px;
}

.topic-name {
     margin-bottom: 0.3rem;
     font-size: 1rem;
     line-height: 1.5rem;
     font-weight: 500;
     line-height: 1.25rem;
}

.topic-card-middle {
     display: flex;
     margin-bottom: 1.5rem;
     align-items: flex-start;
     flex-direction: column;
     gap: 5px;
}

.topic-level {
     margin-right: 0.5rem;
     font-size: 0.875rem;
     line-height: 1.25rem;
     font-weight: 500;
     letter-spacing: 0.025em;
}

.topic-star-container {
     display: flex;
}

.icon-star {
     height: 1.8rem;
}

.fill-accent {
     fill: #5df99c;
}

.fill-slate-500 {
     stroke: #64748b;
     fill: transparent;
}

.a-button {
     padding: 0.5rem;
     padding-left: 2rem;
     padding-right: 2rem;
     padding-top: 0.5rem;
     color: #ffffff;
     font-weight: 600;
     border-radius: 9999px;
     background: #000027;
     text-decoration: none;
     cursor: pointer;
}

.professors-list {
     display: flex;
     flex-direction: column;
     gap: 25px;
     padding-top: 50px;
     align-items: center;
     width: 100%;
}

.topic-image {
     width: 93px;
     height: 93px;
     border-radius: 50%;
     position: relative;
     bottom: 30px;
     overflow: hidden;
     img {
          width: 100%;
          height: 100%;
          object-fit: cover;
     }
}

.topic-data {
     display: flex;
     gap: 20px;
     padding-bottom: 20px;
}

.btn-finish {
     height: 34px;
     width: 111px;
     font-size: 15px;
     margin-top: 13px !important;
     border-radius: 5px !important;
     font-weight: 600 !important;
     background: #5df99c !important;
     color: #000027 !important;
     transition: all 0.2s ease-in-out !important;
     box-shadow: 0 5px 5px #00000028 !important;
}

.btn-finish:hover {
     color: #ffffff !important;
     background: #000027 !important;
}

.title-congrats {
     margin-top: 60px;
     font-size: 32px !important;
     font-weight: 600 !important;
}

.p-congrats {
     margin-top: 0 !important;
     font-size: 32px !important;
     line-height: 37px !important;
}

.p-finish {
     font-size: 15px !important;
}

.stars-header {
     width: 90%;
     max-width: 454px;
}

.back-icon-container {
     cursor: pointer;
     padding-bottom: 80px;
     width: 100%;
     display: flex;
     justify-content: flex-start;
     padding-left: 80px;
}