.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 1rem 2rem;
  margin: 0 auto;
  margin-top: 2rem;

  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  user-select: none;

  background-color: var(--green);
  color: var(--white);

  border: 0;
  border-radius: var(--border-radius);

  box-shadow: 4px 4px 12px -4px var(--green-shadow);

  transition-duration: var(--hover);
  transition-timing-function: ease-in-out;
  transition-property: background-color, box-shadow;

  &.blue {
    background-color: var(--blue);
    box-shadow: 4px 4px 12px -4px var(--blue-shadow);
  }

  &:hover {
    transition: none;
    background-color: var(--black);
    box-shadow: 4px 4px 12px -4px var(--black-shadow);
  }

  &:disabled {
    transition: none;
    background-color: var(--green-shadow);

    &:hover {
      background-color: var(--green-shadow);
      box-shadow: 4px 4px 12px -4px var(--green-shadow);
    }
  }

  & > svg.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 0.5rem;

    width: 18px;
    height: 18px;

    animation: spin 1s linear infinite;

    path {
      stroke: var(--white);
    }

    @keyframes spin {
      0% {
        transform: rotateZ(0);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }
  }
}

.logo {
  height: 4rem;
  width: 16rem;

  margin-top: 0.25rem;
  margin-bottom: 3rem;
}

.screen {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  height: calc(var(--unit) * 100);
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  transition: var(--animation) ease-in-out transform;
  transform-origin: top left;
  background-color: var(--white);

  overflow-y: auto;

  & > .top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: max(calc(var(--unit) * 100), 568px);
    height: max-content;
    width: 100%;
  }
}

.dev {
  text-decoration: none;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  width: 10px;
  color: #ddd;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.error-msg {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 3rem 0 1rem;

  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;

  width: 100%;
  max-width: 36rem;
}
